import React from 'react'
import octopusHairCutImg from "./../../../Images/HairSalonInnerPage/Octopus Hair Cut.png"

const OctopusHairCutImg = () => {
  return (
    <div className="faceImgContainer">
    <img src={octopusHairCutImg} alt="octopusHairCutImg" />
  </div>
  )
}

export default OctopusHairCutImg
