import React from 'react'
import facialJpImg from "./../../../Images/JP_Nagar/Facials.png";

const FacialInLavelleImg = () => {
  return (
    <div className="hairSalonInLavelleImg">
    <img src={facialJpImg} alt="facialInLavelleImg" loading='lazy' />
  </div>
  )
}

export default FacialInLavelleImg
