import React from 'react'
import butterflyHaircutImg from "./../../../Images/HairSalonInnerPage/Butterfly Hair Cut.png"

const ButterflyHaircutImg = () => {
  return (
    <div className="faceImgContainer">
    <img src={butterflyHaircutImg} alt="butterflyHaircutImg" />
  </div>
  )
}

export default ButterflyHaircutImg
